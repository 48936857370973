'use strict';
var toastr = require('toastr');
var isMobile = !($(window).width() >= 768);


$(document).ready(function () {
    $('.js-verify-phone-btn').on('click', function (e) {
        e.preventDefault();
        $('.resendSms').addClass('disabled');
        $('.timerText').show();
        var gTagData;
        var url = $(this).data('verification-url');
        var $form = $('#registeration-form');
        var countryCode = $('#countrycode').val();
        var email = $('#registration-form-email').val();
        var phoneValue = $('.js-phone-mobile').val();
        var phoneNumber = countryCode + phoneValue;
        var otpMethod = $('#otp-verification-type').val();
        var mode = 'sms';
        if (otpMethod) {
            mode = otpMethod;
        }

        var data = {
            phoneNumber: phoneNumber,
            email: email,
            countryCode: countryCode,
            mode: mode
        };
        if ($(this).hasClass('resendSms')) {
            data = {
                phoneNumber: phoneNumber
            };
        }
        var csrfField = $('.js-csrf');
        data[csrfField.attr('name')] = csrfField.val();
        gTagData = {
            phoneNumber: phoneNumber
        };
        $.spinner().start();
        // eslint-disable-next-line no-undef
        grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                data.token = token;
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    success: function (response) {
                        gTagData = {
                            phoneNumber: phoneNumber,
                            message: response.message,
                            email: $form.find('#registration-form-email')[0].value,
                            firstname: $form.find('#registration-form-fname')[0].value,
                            lastname: $form.find('#registration-form-lname')[0].value,
                            gender: $form.find('.radio-button-group .active').attr('data-type'),
                            countryCode: $form.find('.country-code-container .custom-select span:first').text(),
                            phone: $form.find('.js-phone-mobile')[0].value
                        };
                        if (!response.success) {
                            $.spinner().stop();
                            // eslint-disable-next-line no-unused-expressions
                            isMobile ? alert(response.message) : toastr.error(response.message);
                            $(document).trigger('verification:error', gTagData);
                            return;
                        }
                        $(document).trigger('verification:success', gTagData);

                        $('#registeration-step-1').fadeOut(50, function () {
                            $('.singleOtp').val(''); // Clear OTP inputs after registration step 1
                            var $num = $('.js-phone-mobile').val();
                            var $code = $('#countrycode').val();
                            $('.new-login-tabs').fadeOut();
                            $('#registeration-step-2').find('.js-add-number').text($code + $num);
                            $('#registeration-step-2').fadeIn(100);
                            $('.loginBox').hide();
                            $('.nomargi-flex .js-registeration-goback-otp').show();
                            $('.account-tabs').hide();
                            $('.js-main-title').hide();
                            $('.login-profile-selection').addClass('d-none');
                            window.scrollTo(0, 0);
                            var timer = $('.resendSms').data('enableforseconds');
                           
                            var counter = timer;
                            var interval = setInterval(function () {
                                counter--;
                                // Display 'counter' wherever you want to display it.
                                if (counter <= 0) {
                                    clearInterval(interval);
                                    $('.resendSms').removeClass('disabled');
                                    $('.timerText').hide();
                                    $('.timerText span').html(timer);
                                    return;
                                    // eslint-disable-next-line no-else-return
                                } else {
                                    $('.timerText span').text(counter);
                                }
                            }, 1000);
                        });
                        $('#registeration-profile-pic').fadeOut(50);

                        // var token = response.token;
                        // $('.otp-token').val(token);
                        $('.js-btn-save').removeAttr('disabled').removeClass('is-disabled');
                        $.spinner().stop();
                    },
                    error: function (error) {
                        $('.js-register-btn').prop('disabled', false);
                        $('.js-register-btn').removeClass('is-disabled');
                        $.spinner().stop();
                        // eslint-disable-next-line no-unused-expressions
                        isMobile ? alert(error) : toastr.error(error);
                    }
                });
            });
        });
    });

    $('.js-verify-phone-btn-enoc').on('click', function (e) {
        e.preventDefault();
        if ($('input#agreeTerms').is(':checked')) {
            $('.resendSms').addClass('disabled');
            $('.timerText').show();
            var gTagData;
            var url = $(this).data('verification-url');
            var $form = $('#partner-register');
            var countryCode = $('#countrycode').val();
            var email = $('#registration-form-email').val();
            var phoneValue = $('.js-phone-mobile').val();
            var phoneNumber = countryCode + phoneValue;
            var enocCode = $('#partner-register input[name="enocCode"]').val();
            var codes = enocCode !== '' ? [enocCode] : [];
            var data = {
                phoneNumber: phoneNumber,
                email: email,
                countryCode: countryCode,
                enocCodes: JSON.stringify(codes)
            };
            if ($(this).hasClass('resendSms')) {
                data = {
                    phoneNumber: phoneNumber
                };
            }
            var csrfField = $('.js-csrf');
            data[csrfField.attr('name')] = csrfField.val();
            gTagData = {
                phoneNumber: phoneNumber
            };
            // eslint-disable-next-line no-undef
            grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
                grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                    data.token = token;
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: data,
                        success: function (response) {
                            gTagData = {
                                phoneNumber: phoneNumber,
                                message: response.message,
                                email: $form.find('#registration-form-email')[0].value,
                                firstname: $form.find('#registration-form-fname')[0].value,
                                lastname: $form.find('#registration-form-lname')[0].value,
                                gender: $form.find('.radio-button-group .active').attr('data-type'),
                                countryCode: $form.find('.country-code-container .custom-select span:first').text(),
                                phone: $form.find('.js-phone-mobile')[0].value
                            };
                            if (!response.success) {
                            // eslint-disable-next-line no-unused-expressions
                                isMobile ? alert(response.message) : toastr.error(response.message);
                                $(document).trigger('verification:error', gTagData);
                                return;
                            }
                            $(document).trigger('verification:success', gTagData);

                            $('#registeration-step-1').fadeOut(50, function () {
                                var $num = $('.js-phone-mobile').val();
                                var $code = $('#countrycode').val();
                                $('.new-login-tabs').fadeOut();
                                $('#registeration-step-2').find('.js-add-number').text($code + $num);
                                $('#registeration-step-2').fadeIn(100);
                                $('.loginBox').hide();
                                $('.nomargi-flex .js-registeration-goback-otp').show();
                                window.scrollTo(0, 0);
                                var timer = $('.resendSms').data('enableforseconds');

                                var counter = timer;
                                var interval = setInterval(function () {
                                    counter--;
                                    // Display 'counter' wherever you want to display it.
                                    if (counter <= 0) {
                                        clearInterval(interval);
                                        $('.resendSms').removeClass('disabled');
                                        $('.timerText').hide();
                                        $('.timerText span').html(timer);
                                        return;
                                    // eslint-disable-next-line no-else-return
                                    } else {
                                        $('.timerText span').text(counter);
                                    }
                                }, 1000);
                            });
                            $('#registeration-profile-pic').fadeOut(50);

                            // var token = response.token;
                            // $('.otp-token').val(token);
                            $('.js-btn-save').removeAttr('disabled').removeClass('is-disabled');
                        },
                        error: function (error) {
                            $('.js-register-btn').prop('disabled', false);
                            $('.js-register-btn').removeClass('is-disabled');

                            // eslint-disable-next-line no-unused-expressions
                            isMobile ? alert(error) : toastr.error(error);
                        }
                    });
                });
            });
        } else {
            alert(resources.Gloabl$agreeTerms);
        }
    });

    $('.resendSms').on('click', function (e) {
        e.preventDefault();
        $('.resendSms').addClass('disabled');
        $('.timerText').show();
        var gTagData;
        var url = $(this).data('verification-url');
        var countryCode = $('#countrycode').val();
        var email = $('#registration-form-email').val();
        var phoneValue = $('.js-phone-mobile').val();
        var phoneNumber = countryCode + phoneValue;
        var otpMethod = $('#otp-verification-type').val();
        var mode = 'sms';
        if (otpMethod) {
            mode = otpMethod;
        }
        var data = {
            phoneNumber: phoneNumber,
            email: email,
            mode: mode
        };
        if ($(this).hasClass('resendSms') && mode === 'sms') {
            data = {
                phoneNumber: phoneNumber
            };
        }
        var csrfField = $('.js-csrf');
        data[csrfField.attr('name')] = csrfField.val();
        gTagData = {
            phoneNumber: phoneNumber
        };
        grecaptcha.ready(function () {
            grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                data.token = token;
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    success: function (response) {
                        gTagData = {
                            phoneNumber: phoneNumber,
                            message: response.message
                        };
                        if (!response.success) {
                            isMobile ? alert(response.message) : toastr.error(response.message);
                            $(document).trigger('verification:error', gTagData);
                            return;
                        }
                        var alertMessage = mode === 'email' ? resources.OTPResendEmail : resources.OTPResend;
                        isMobile ? alert(alertMessage) : toastr.error(alertMessage);
                        $(document).trigger('verification:success', gTagData);

                        $('#registeration-step-1').fadeOut(50, function () {
                            $('#registeration-step-2').fadeIn(100);
                            $('.loginBox').hide();
                            $('.new-login-tabs').fadeOut();
                            var timer = $('.resendSms').data('enableforseconds');

                            var counter = timer;
                            var interval = setInterval(function () {
                                counter--;
                                // Display 'counter' wherever you want to display it.
                                if (counter <= 0) {
                                    clearInterval(interval);
                                    $('.resendSms').removeClass('disabled');
                                    $('.timerText').hide();
                                    $('.timerText span').html(timer);
                                    return;
                                    // eslint-disable-next-line no-else-return
                                } else {
                                    $('.timerText span').text(counter);
                                }
                            }, 1000);
                        });

                        $('#registeration-profile-pic').fadeOut(50);

                        // var token = response.token;
                        // $('.otp-token').val(token);
                        $('.js-btn-save').removeAttr('disabled').removeClass('is-disabled');
                    },
                    error: function (error) {
                        $('.js-register-btn').prop('disabled', false);
                        $('.js-register-btn').removeClass('is-disabled');

                        isMobile ? alert(error) : toastr.error(error);
                    }
                });
            });
        });
    });
 
    $(document).on('click', '.js-go-back-otp', function (e) {
        e.preventDefault();
        $('#registeration-step-2').fadeOut(50, function () {
            $('#registeration-step-2').find('.js-add-number').text('');
            $('#registeration-step-1').fadeIn(100);
            $('#registeration-profile-pic').fadeIn(100);
            $('.login-profile-selection').removeClass('d-none');
            $('.loginBox').hide();
        
            $('.account-tabs').show();
            $('.js-main-title').show();
            window.scrollTo(0, 0);
        });
    });
});
